import { customCharts } from './custom-charts.js';
import { dateTimePicker } from './datetime-picker.js';

$(document).ready(function() {
    
    //Get current year
    const currentYear = new Date().getFullYear();
    $('.js-current-year').text(currentYear);

    //Get current time
    var currentHour = new Date().getHours();

    //Dashboard greeting
    var greet;
    if (currentHour < 12) {
        greet = 'Good Morning';
    } else if (currentHour >= 12 && currentHour <= 17) {
        greet = 'Good Afternoon';
    } else if (currentHour >= 17 && currentHour <= 24) {
        greet = 'Good Evening';
    }
    $('.js-greeting').html(greet + ', ');

    // Navigation
    var navMain = $('.nav-header'),
        navMainHamburger = navMain.find('.nav-header-hamburger'),
        navMobile = $('.nav-mobile');

    navMainHamburger.click(function() {
        var $this = $(this);
        if (!$this.hasClass('active')) {
            $this.addClass('active');
            navMobile.show();
            setTimeout(function() {
                navMobile.addClass('show');
            }, 200);
        } else {
            $this.removeClass('active');
            navMobile.removeClass('show');
            setTimeout(function() {
                navMobile.hide();
            }, 350);
        }
    });

    //scrollSpy Disclosure Information
    if($('#disclosure-scrollto-nav').length) {
        var scrollSpy = new bootstrap.ScrollSpy(document.body, {
            target: '#disclosure-scrollto-nav'
        });
    }

    //Checkbox selection
    $('.js-check-all').change(function() {
        const group = $(this).data('group-parent');
        $(`input[data-group="${group}"]`).prop('checked', $(this).is(':checked')).change();
        $(this).is(':checked') ? $(this).next().removeClass('text-muted') : $(this).next().addClass('text-muted');
    });

    $('input[data-group]').change(function() {
        const group = $(this).data('group');
        const count = $(`input[data-group="${group}"]:checked`).length;
        $(`[data-group-count="${group}"]`).text(count).parent().removeClass('text-muted');
        $(`[data-group-button="${group}"]`).prop('disabled', count == 0);
        if(count == 0) {$(`[data-group-count="${group}"]`).parent().addClass('text-muted');}
    });

    //Set active class to accordion-item
    $('.accordion-button').on('click', function (e) {
        if ($(this).attr('aria-expanded') === 'true') {
            $(this).closest('.accordion').find('.accordion-item').removeClass('active');
            $(this).closest('.accordion-item').addClass('active');
        } else {
            $(this).closest('.accordion').find('.accordion-item').removeClass('active');
        }
    });

    //Online statement consent    
    $('[data-statement="online"]').click(function() { 
        $('[data-statement-consent="reject"]').data('consent-trigger', $(this).attr('id'));
    });
    $('[data-statement-consent="reject"]').click(function() {
        $("#" + $(this).data('consent-trigger')).parent().prev().find('input[data-statement="paper"]').prop('checked', true);
        $(this).attr('data-consent-trigger', '');
    });

    //Date Time Picker (flatpickr)
    let dat = dateTimePicker();

    //flatpickr (date & time picker)
    const $datePickerEn = $(".datepicker-input-en");
    const $datePickerFr = $(".datepicker-input-fr");

    // English datepicker
    $datePickerEn.flatpickr({
        locale: {
            weekdays: {
                shorthand: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                longhand: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            },
            months: {
                shorthand: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
                longhand: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            },
        },
        enableTime: false,
        disableMobile: "true",
        altInput: true,
        altFormat: "M j Y",
        dateFormat: "Y-m-d"
    });
    
    // French datepicker
    $datePickerFr.flatpickr({
        locale: {
            weekdays: {
                shorthand: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
                longhand: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
            },
            months: {
                shorthand: ['JANV', 'FÉVR', 'MARS', 'AVRIL', 'MAI', 'JUIN', 'JUIL', 'AOÛT', 'SEPT', 'OCT', 'NOV', 'DÉC'],
                longhand: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            },
        },
        enableTime: false,
        disableMobile: "true",
        altInput: true,
        altFormat: "j M Y",
        dateFormat: "Y-m-d"
    });
     
     //Custom Charts
     let val = customCharts(); 

});